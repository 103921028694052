@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer base {
  h1 {
    @apply text-2xl font-bold tracking-tight text-gray-900;
  }
  h2 {
    @apply text-xl font-bold tracking-tight text-gray-900;
  }
}

.react-datepicker {
  @apply font-sans outline-none border-gray-900 rounded-xl !important;
}

.react-datepicker__header {
  @apply bg-gray-300 border-b border-gray-300 rounded-t-xl !important;
}

.react-datepicker__current-month {
  @apply text-base !important;
}

.react-datepicker__day {
  @apply outline-none !important;
}

.react-datepicker__day:hover {
  @apply bg-gray-300 rounded-full !important;
}

.react-datepicker__day--selected {
  @apply bg-brand-500 text-white rounded-full !important;
}

.react-datepicker__day--selected:hover {
  @apply bg-brand-700 rounded-full !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  @apply text-brand-500 rounded-full bg-inherit !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  @apply rounded-full bg-gray-300 !important;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300 !important;
}

.react-datepicker__navigation {
  @apply text-gray-900 !important;
}

.react-datepicker__navigation-icon--next::before {
  @apply border-brand-500 !important;
}

.react-datepicker__navigation--next:hover
  .react-datepicker__navigation-icon--next::before {
  @apply border-brand-700 !important;
}

.react-datepicker__navigation-icon--previous::before {
  @apply border-brand-500 !important;
}

.react-datepicker__navigation--previous:hover
  .react-datepicker__navigation-icon--previous::before {
  @apply border-brand-700 !important;
}

.react-datepicker__input-container input {
  @apply text-gray-900 font-normal !important;
}
